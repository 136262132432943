import $ from 'jquery';
import 'bootstrap/js/dist/modal';


const modalParent = $('#simpleContentModal');

$('body').on('click', '[data-toggle=modal]', function() {
    console.log('MODAL')

    let modalTitle = $(this).data('title');
    let modalUrl = $(this).data('url');
    let modalNumber = $(this).data('number');
    let modalIdNews = $(this).data('idnews');
    let modalIdProject = $(this).data('idproject');

    /* Richiesta ajax per prendere il contenuto html
    e iniettarlo dentro al Modal */
    $.ajax({
        url: modalUrl,
        method: 'GET',
        data: {
            modalTitle: modalTitle,
            modalNumber: modalNumber,
            modalIdNews: modalIdNews,
            modalIdProject: modalIdProject
        },
        success: function(response) {
            modalParent.find('.modal-dialog').removeClass('modal-full');

            if(response.html) {
                modalParent.find('.modal-body').replaceWith(response.html);
                if (response.isNews) {
                    modalParent.find('.modal-dialog').addClass('modal-full');
                }
                modalParent.modal('show');
            }
        }
    });
});


// Modal del contact form
$('input[name="attached"]').addClass('custom-file-input').closest('div').addClass('custom-file');
$('input[name="attached"]').closest('div').append(
    $('<label class="custom-file-label" for="customFile"><span>Carica file</span></label>')
);

$('#div_id_attached .custom-file-label').append('<span class="filename"></span>')
$('input[name="attached"]').on('change', function(){
    var fileUpload = $(this)[0].files[0]
    $('#div_id_attached .custom-file-label .filename').text(fileUpload.name)

    if(fileUpload.size > 5242880) {
        $('input[name="attached"]').addClass('is-invalid');
        $('#div_id_attached').append('<div class="invalid-feedback d-block">Il file pesa più di 5MB.</div>')
        $('form input[type="submit"').attr('disabled', true)
    } else {
        $('input[name="attached"]').removeClass('is-invalid');
        $('.invalid-feedback').remove()
        $('form input[type="submit"').prop('disabled', false)
    }
})

$('#importantDocument').modal('show');
$('#importantDocument .modal-content').on('contextmenu', function(event){
    event.preventDefault();
});